<template>
    <div class="show-img">
        <img :src="this.params.data.imageUrl" class="round w-full img-checkin"/>
    </div>
</template>
<script>
export default {
    name: 'CellRendererActions',
}
</script>
<style scoped>

.show-img .img-checkin {
    display: block;
    height: 95px;
    padding: 5px;
    width: 95px !important;
    object-fit: cover;
    object-position: center;
}

.show-img {
    position: relative
}

</style>
