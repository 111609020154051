<template>
  <!-- Start render data AI -->
  <div class="flex-container row-mask mb-2">
    <vx-tooltip class="f-r" :text="$t('menuEvent.emoji')" style="flex: 1;" position="top">
      <div class="row-ratio">
        <img :src="imgEmoji" class="img-emoji" />
        <span class="text-ratio">{{this.params.data.emotionPredict ? this.params.data.emotionPredict.label : $t('menuHistory.undefined')}}</span>
      </div>
    </vx-tooltip>
    <vx-tooltip class="f-r" v-bind:text="textMask" style="flex: 1;" position="top">
      <div class="row-ratio">
        <img v-bind:src="imgMaskRender" class="img-emoji" />
        <span class="text-ratio">{{this.params.data.maskPredict ? Number.parseInt(Number(this.params.data.maskPredict.score) * 100) + '%' : $t('menuHistory.undefined')}}</span>
      </div>
    </vx-tooltip>
    <vx-tooltip class="f-r" :text="textLiveness" style="flex: 1;" position="top">
      <div class="row-ratio">
        <img :src="imgLiveness" class="img-emoji" />
        <span class="text-ratio">{{this.params.data.spoofingPredict ? Number.parseInt(Number(this.params.data.spoofingPredict.score) * 100) + '%' : $t('menuHistory.undefined')}}</span>
      </div>
    </vx-tooltip>
  </div>
  <!-- End render data AI -->
</template>
<script>
import imgLiveNess0 from "../../../../assets/images/pages/ic_liveness_0.png";
import imgLiveNess1 from "../../../../assets/images/pages/ic_liveness_1.png";
import imgNoMask from "../../../../assets/images/pages/ic_no_mask.png";
import imgMask from "../../../../assets/images/pages/ic_mask.png";
import imgHappy from "../../../../assets/images/pages/ic_emoji_happy.png";
import imgNeutral from "../../../../assets/images/pages/ic_emoji_neutral.png";
import imgAngry from "../../../../assets/images/pages/ic_emoji_angry.png";
import imgSad from "../../../../assets/images/pages/ic_emoji_sad.png";
import imgSuprise from "../../../../assets/images/pages/ic_emoji_suprise.png";

export default {
  name: "CellRendererDataAI",
  data() {
    return {
      imgLiveNess1: null,
      imgLiveNess0: null,
      imgNoMask: null,
      imgHappy: null,
      imgNeutral: null,
      imgAngry: null,
      imgSad: null,
      imgSuprise: null,
      imgMask: null,
    };
  },
  methods: {},
  created() {
    this.imgLiveNess1 = imgLiveNess1;
    this.imgLiveNess0 = imgLiveNess0;
    this.imgNoMask = imgNoMask;
    this.imgHappy = imgHappy;
    this.imgNeutral = imgNeutral;
    this.imgAngry = imgAngry;
    this.imgSad = imgSad;
    this.imgSuprise = imgSuprise;
    this.imgMask = imgMask;
  },
  computed: {
    imgEmoji() {
      let emotionEcon = this.imgHappy;
      if (this.params.data.emotionPredict) {
        switch (this.params.data.emotionPredict.label) {
          case "angry":
            emotionEcon = this.imgAngry;
            break;
          case "happy":
            emotionEcon = this.imgHappy;
          case "neutral":
            emotionEcon = this.imgNeutral;
            break;
          case "sad":
            emotionEcon = this.imgSad;
            break;
          case "suprise":
            emotionEcon = this.imgSuprise;
            break;
        }
      }

      return emotionEcon;
    },
    imgMaskRender() {
      let iconMask = this.imgNoMask;
      if (this.params.data.maskPredict) {
        switch (this.params.data.maskPredict.label) {
          case "no_mask":
            iconMask = this.imgNoMask;
            break;
          case "mask":
            iconMask = this.imgMask;
        }
      }
      return iconMask;
    },
    imgLiveness() {
      let iconMask = this.imgLiveNess1;
      if (this.params.data.spoofingPredict) {
        switch (this.params.data.spoofingPredict.label) {
          case "0":
            iconMask = this.imgLiveNess0;
            break;
          case "1":
            iconMask = this.imgLiveNess1;
        }
      }
      return iconMask;
    },
    textMask() {
      let iconMask = this.$t('menuEvent.noMask');
      if (this.params.data.maskPredict) {
        switch (this.params.data.maskPredict.label) {
          case "no_mask":
            iconMask = this.$t('menuEvent.noMask');
            break;
          case "mask":
            iconMask = this.$t('menuEvent.mask');
        }
      }
      return iconMask;
    },
    textLiveness() {
      let iconMask = this.$t('menuEvent.auth');
      if (this.params.data.spoofingPredict) {
        switch (this.params.data.spoofingPredict.label) {
          case "0":
            iconMask = this.$t('menuEvent.fake');
            break;
          case "1":
            iconMask = this.$t('menuEvent.auth');
        }
      }
      return iconMask;
    },
  },
};
</script>
<style scoped>
.img-emoji {
  width: 1.4rem;
  height: 1.4rem;
  /* margin: auto; */
}

.row-mask {
  /* justify-content: space-between; */
  display: flex;
  padding-top: 1rem;
  line-height: 100px;
}

.row-ratio {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-items: center;
}

.text-ratio {
  font-size: 12px;
  font-weight: 400 !important;
  line-height: 30px;
  height: 30px !important;
}
</style>
