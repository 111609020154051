<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="vx-col md:w-full flex items-center mb-5">
                <TitleScreen></TitleScreen>
            </div>
            <div class="vx-row">
                <div class="vx-col md:w-1/3 w-full mt-2">
                    <label class="vs-input--label">{{ $t('menuHistory.branch') }}</label>
                    <v-select class="" v-model="selectedOrganizationBranch"
                              :options="organizationBranchOptions">
                        <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                    </v-select>
                </div>
                <div class="vx-col md:w-1/3 w-full mt-2">
                    <label class="vs-input--label">{{ $t('menuHistory.employee') }}</label>
                    <v-select class="" v-model="selectedEmployee" :clearable="false" :options="employeeOptions">
                        <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                    </v-select>
                </div>
                <div class="vx-col md:w-1/3 w-full mt-2">
                    <label class="vs-input--label">{{ $t('menuHistory.time') }}</label>
                    <!-- <div class="flex-grow"> -->
                        <date-range-picker
                            ref="picker" class="w-full"
                            :locale-data="{format: 'dd/mm/yyyy' }"
                            :opens="'left'"
                            @update="dateRangePicker"
                            v-model="dateRange">
                        </date-range-picker>
                    <!-- </div> -->
                </div>
            </div>

            <div class="vx-row">
                <div class="vx-col md:w-1/3 w-full mt-2">
                    <div class="flex-grow">
                        <label class="vs-input--label">{{ $t('menuEvent.selectDevice') }}</label>
                        <v-select class="" v-model="selectedDevice" :clearable="false"
                                  :options="deviceOptions">
                            <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                        </v-select>
                    </div>
                </div>
                <div class="vx-col md:w-1/3 w-full mt-2">
                    <div class="flex-grow" v-if="this.selectedDevice.value == 'camera'">
                        <label class="vs-input--label">{{ $t('menuHistory.selectCamera') }}</label>
                        <v-select class="" v-model="selectedCamera" :clearable="false"
                                  :options="cameraOptions">
                            <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                        </v-select>
                    </div>
                    <div class="flex-grow" v-if="this.selectedDevice.value == 'pad'">
                        <label class="vs-input--label">{{ $t('menuEvent.selectPad') }}</label>
                        <v-select class="" v-model="selectedPad" :clearable="false"
                                  :options="padsData">
                            <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                        </v-select>
                    </div>
                </div>
                
                <div class="vx-col md:w-1/3 w-full mt-26">
                    <vs-button class="float-right" @click="filter" :disabled="!validateForm">{{ $t('menuHistory.filter') }}</vs-button>
                    <vs-button class="mr-5 float-right" @click="exportFile" :disabled="!validateForm">
                        {{ $t('menuHistory.createReport') }}
                    </vs-button>
                </div>
                
            </div>
            <div class="flex flex-wrap items-center mt-4">
                <div class="flex-grow"></div>
                <vs-input class="" v-model="searchQuery" @input="updateSearchQuery" :placeholder="$t('button.search')"/>
            </div>
            <!-- AgGrid Table -->
            <div class="custom-table-inout">
                <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="workShiftData"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :localeText=" {noRowsToShow: this.$t('menuCustomer.noRowsToShow')}"
                         :suppressPaginationPanel="true">
                </ag-grid-vue>
            </div>
            

            <div class="vx-row mt-10 items-center">
                <div class="vx-col md:w-1/2">
                    <Pagination
                        :itemList="workShiftData"
                        :currentPage="currentPage"
                        :paginationPageSize="paginationPageSize"
                        :gridApi="gridApi"
                    />
                </div>
                <div class="vx-col md:w-1/2">
                    <vs-pagination
                        :total="totalPages"
                        :max="maxPageNumbers"
                        v-model="currentPage"/>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>
.vue-daterange-picker .ranges {
    float: right;
    border-left: 1px solid #ddd;
}

.daterangepicker {
    border-left: none !important;
}

.title-page {
    font-size: 2.2rem;
    font-weight: bold;
    display: flex;
}

.text-font {
    opacity: 0.7;
}

.reportrange-text {
    border-radius: 5px;
    height: 38px;
    line-height: 27px;
}

.mt-26 {
    margin-top: 26px;
}

.grid-cell-centered {
  text-align: center;
  justify-content: center;
}
</style>

<style lang="scss">
.custom-table-inout .ag-theme-material .ag-ltr .ag-cell {
    line-height: 100px;
}
</style>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import authConfig from '@/../auth_config.json';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'; // Vuesax
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";
import Pagination from "../../ui-elements/Pagination.vue"
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import CellRendererImg from "../confirmTimeKeeping/cell-renderer/CellRendererImg"
import CellRendererDataAI from "./cell-renderer/CellRendererDataAI"
import DateRangePicker from '../../../components/vue2-daterange-picker/components/DateRangePicker.vue'
import TitleScreen from "../../../layouts/main/TitleScreen";

export default {
    components: {
        AgGridVue,
        vSelect,
        // Cell Renderer
        Datepicker,
        CellRendererActions,
        Pagination,
        flatPickr,
        CellRendererImg,
        DateRangePicker,
        CellRendererDataAI,
        TitleScreen
    },
    data() {
        return {
            showImg: false,
            imageUrl: "",
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {
                rowHeight: 100
            },
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: this.$t('menuHistory.employeeCode'),
                    field: 'employeeCode',
                    width: 70,
                },
                {
                    headerName: this.$t('menuHistory.employeeName'),
                    field: 'fullName',
                    width: 160
                },
                {
                    headerName: this.$t('menuHistory.device'),
                    field: 'deviceType',
                    width: 100,
                },
                {
                    headerName: this.$t('menuHistory.deviceName'),
                    field: 'cameraName',
                    width: 150,
                },
                {
                    headerName: this.$t('menuHistory.img'),
                    field: 'imageUrl',
                    width: 150,
                    cellRendererFramework: 'CellRendererActions',
                },
                {
                    headerName: this.$t('menuHistory.featureAi'),
                    width: 200,
                    cellRendererFramework: 'CellRendererDataAI',
                },
                {
                    headerName: this.$t('menuHistory.time'),
                    field: 'checkinTime',
                    width: 150,
                }
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
                CellRendererDataAI,
            },
            employeeData: [],
            workShiftData: [],
            organizationBranchOptions: [],
            employeeOptions: [
                {value: null, label: this.$t('menuHistory.all')}
            ],
            selectedOrganizationBranchValue: null,
            selectedEmployeeValue: null,
            cameraOptions: [{value: null, label: this.$t('menuHistory.all')}],
            padsData: [{value: null, label: this.$t('menuHistory.all')}],
            deviceOptions: [
                {value: null, label: this.$t('menuHistory.all')},
                {value: 'camera', label: "Camera"},
                {value: 'pad', label: "Pad"},
            ],
            deviceId: null,
            cameraId: null,
            padId: null,
            dateRange: {
                endDate: new Date(),
                startDate: (() => {
                    let date = new Date();
                    date.setHours(0, 0, 0);
                    return date
                })()
            },
        }
    },
    created() {
        this.selectedEmployeeValue = this.$route.query.employeeId ? parseInt(this.$route.query.employeeId) : null;
        //this.start = moment(this.start).set({hour:0,minute:0,second:0,millisecond:0});
        this.initScreen()
        this.$eventBus.$on('i18n', (event) => {
            this.columnDefs[0].headerName = this.$t('menuHistory.employeeCode');
            this.columnDefs[1].headerName = this.$t('menuHistory.employeeName');
            this.columnDefs[3].headerName = this.$t('menuHistory.img');
            this.columnDefs[4].headerName = this.$t('menuHistory.time');
            this.gridOptions.api.setColumnDefs(this.columnDefs);

        })
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateEmployee'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_EMPLOYEE', false);
            }
        }
    },
    computed: {
        selectedCamera: {
            get() {
                if (this.cameraOptions.length > 0) {
                    return {
                        label: this.cameraOptions.find(x => x.value == this.cameraId).label,
                        value: this.cameraId
                    }
                }
            },
            set(obj) {
                this.cameraId = obj.value
            }
        },
        selectedPad: {
            get() {
                if (this.padsData.length > 0) {
                    return {
                        label: this.padsData.find(x => x.value == this.padId).label,
                        value: this.padId
                    }
                }
            },
            set(obj) {
                this.padId = obj.value
            }
        },

        selectedDevice: {
            get() {
                if (this.deviceOptions.length > 0) {
                    return {
                        label: this.deviceOptions.find(x => x.value == this.deviceId).label,
                        value: this.deviceId
                    }
                }
            },
            set(obj) {
                this.deviceId = obj.value
            }
        },
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.selectedOrganizationBranchValue),
                    value: this.selectedOrganizationBranchValue
                }
            },
            async set(obj) {
                this.selectedOrganizationBranchValue = obj ? obj.value : null;
                this.loadEmployee();
                this.getHardwareDevice();
                this.getCameraByBranch()
                this.selectedDevice = this.deviceOptions[0]
                this.selectedCamera = this.cameraOptions[0]
                this.selectedPad = this.padsData[0]
            }
        },
        selectedEmployee: {
            get() {
                return {
                    label: this.employeeString(this.selectedEmployeeValue),
                    value: this.selectedEmployeeValue
                }
            },
            set(obj) {
                this.selectedEmployeeValue = obj.value
            }
        }
    },
    methods: {
        dateRangePicker: function () {
            this.dateRange.startDate = new Date(this.dateRange.startDate.setHours(0, 0, 0));
            this.dateRange.endDate = new Date(this.dateRange.endDate.setHours(23, 59, 59));
        },
        getHardwareDevice(){
            return new Promise((resolve, reject) => {
                this.$vs.loading()
                let data = {
                    organizationBranchId: this.selectedOrganizationBranchValue
                }
                this.$crm.post('device/hardware/find-by-condition', data).then(res => {
                    this.padsData = res.data.map(item => {
                        return {value: item.deviceId, label: item.deviceName}
                    });
                    this.padsData.unshift({value: null, label: this.$t('menuHistory.all')})
                    this.$vs.loading.close()
                    resolve()
                }).catch(err => {
                    this.$vs.loading.close();
                    reject()
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                })
            })
            
        },
        getCameraByBranch() {
            return new Promise((resolve, reject) => {
                let that = this;
                let url = this.selectedOrganizationBranchValue ? `/camera/find-by-organization-branch/${this.selectedOrganizationBranchValue}` : `/camera/all`
                that.$vs.loading();
                that.$crm.post(url).then((response) => {
                    that.cameraOptions = response.data.map(item => {
                        return {value: item.id, label: item.cameraName}
                    });
                    that.cameraOptions.unshift({value: null, label: this.$t('menuHistory.all')})
                    that.$vs.loading.close();
                    resolve()
                }).catch((err) => {
                    that.$vs.loading.close();
                    reject()
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
            
        },
        mouseOver: function () {
            this.active = !this.active;
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || this.$t('menuHistory.determine');
        },
        employeeString(id) {
            let employee = this.employeeOptions.find(x => x.value === id);
            return (employee && employee.label) || this.$t('menuHistory.undefined');
        },
        validateForm() {
            return !this.errors.any()
        },
        filter() {
            let searchInfo = {
                deviceType: this.deviceId,
                organizationBranchId: this.selectedOrganizationBranchValue,
                employeeId: this.selectedEmployee.value ? [this.selectedEmployee.value] : null,
                cameraId: this.deviceId == this.deviceOptions[1].value ? this.cameraId : this.padId,
                start: this.dateRange.startDate,
                end: this.dateRange.endDate
            }
            let that = this;
            that.$crm.post('/time-keeping/all-in-out-org', searchInfo).then((response) => {
                that.workShiftData = response.data.map(res => {
                    // if (res.organizationBranchId != that.selectedOrganizationBranchValue) return;
                    let employee = that.employeeData.find(emp => emp.employeeId == res.employeeId);
                    if (employee) {
                        res.employeeCode = employee.employeeCode;
                        res.fullName = employee.fullName;
                        res.cameraName = '';
                        if(res.deviceType == this.deviceOptions[1].value){
                            let camera = this.cameraOptions.find(x => x.value == res.cameraId);
                            res.cameraName = camera ? camera.label : '';
                            res.deviceType = res.deviceType.toUpperCase();
                        }else if (res.deviceType == this.deviceOptions[2].value){
                            let pad = this.padsData.find(x => x.value == res.cameraId);
                            res.cameraName = pad ? pad.label : '';
                            res.deviceType = res.deviceType.toUpperCase();
                        }
                        return res;
                    }
                })

                that.workShiftData = that.workShiftData.filter(x => x != undefined);
                that.workShiftData.forEach(x => {
                    x.checkinTime = x.createdAt ? moment(x.createdAt).format("DD/MM/YYYY HH:mm:ss") : null;
                    // if (!x.imageUrl.match('http')) {
                        x.imageUrl = authConfig.processorDomain + "storage/" + x.imageUrl
                    // }
                });
                setTimeout(() => {
                        this.gridApi.sizeColumnsToFit()
                    }, 100
                )
                that.$vs.loading.close();
            }).catch((err) => {
                console.log(err)
            });
        },
        exportFile() {
            let searchInfo = {
                organizationBranchId: this.selectedOrganizationBranchValue,
                employeeId: this.selectedEmployee.value ? [this.selectedEmployee.value] : null,
                start: this.dateRange.startDate,
                end: this.dateRange.endDate
            };

            this.$vs.loading();
            this.$crm.post('/time-keeping/create-in-out-org', searchInfo).then((response) => {
                this.$vs.loading.close();
                window.open(authConfig.crmDomain + "file/" + response.data.fileName + "?access_token=" + localStorage.getItem("access_token"), "_blank");
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        async initScreen() {
            // this.loadEmployee();
            // this.getHardwareDevice();
            // this.getCameraByBranch();
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]')
            if (branchList.length == 0) return;
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            })
            this.organizationBranchOptions.unshift({value: null, label: this.$t('menuIntrusion.all')})

            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];
            await this.loadEmployee();
            await this.getHardwareDevice();
            await this.getCameraByBranch()
            this.filter();
        },
        loadEmployee() {
            // if (!this.selectedOrganizationBranchValue) return;
            return new Promise((resolve, reject) => {
                this.$vs.loading();
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                let employeeSearchDTO = {
                    enable: true,
                    organizationBranchId: this.selectedOrganizationBranchValue,
                    organizationId: userInfo.organization.id
                }
                this.$crm.post(`/employee/all`, employeeSearchDTO).then((response) => {
                    this.employeeData = response.data;
                    this.employeeOptions = response.data.map(item => {
                        return {
                            value: item.employeeId,
                            label: item.employeeCode + " " + item.fullName
                        }
                    });
                    this.employeeOptions.unshift({value: null, label: this.$t('menuHistory.all')})

                    this.$vs.loading.close();
                    resolve()
                }).catch((err) => {
                    this.$vs.loading.close();
                    reject()
                    return this.$vs.notify({
                        title: 'Error',
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
            
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>
